<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <div class="header_el">
                    <router-link to="/nodes">
                        <div class="logo_img">
                            <img class="logo_img" src="/assets/Logo-Bianco.png" alt="logo-bianco">
                        </div>
                    </router-link>
                    <div class="title">
                        <h1>|| Trinci-Monitor ||</h1>
                    </div>
                    <ion-item>
                        <ion-label v-if="this.darkMode == false">
                                Dark Mode
                        </ion-label>
                        <ion-label v-else>
                                Light Mode
                        </ion-label>
                        <ion-toggle slot="end" v-model="darkMode" @ionChange="toggleTheme($event.detail.checked)"></ion-toggle>
                    </ion-item>
                </div>
            </ion-toolbar>
            <ion-toolbar>
                <ion-searchbar
                    placeholder="Search by NodeId"
                    show-cancel-button="focus"
                    debounce="0"
                    v-model="searchNode"
                    animated
                    color="light"
                    @ionChange="filterByPk($event)"
                    >
                </ion-searchbar>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-grid>
                <ion-row class="fieldSet_row">
                    <fieldset v-for="(nodeFieldset, i) in fieldSetNodes" :key="i">
                        <legend>
                            <strong>
                               NETWORK NAME: {{ truncateText(nodeFieldset.nw_name) }}||
                            </strong>
                            <strong>
                                ONLINE: {{ nodeFieldset.online }} ||
                            </strong>
                                <strong>
                                TOTAL: {{ nodeFieldset.count }} 
                            </strong>
                             <ion-button class="btn_stats" :router-link="`/map/${nodeFieldset.nw_name}`">Topology</ion-button>
                        </legend>
                        <ion-col class="mobile_center" size="12" size-md="6" size-lg="3" v-for="(node, index) in nodeFieldset.nodes" :key="index"> 
                            <ion-card class="node_col" :class="node.online ? 'border_card_online' : 'border_card_offline'">
                                <ion-card-header>
                                    <ion-card-title class="card_title">NODE ID: <small>{{ node.public_key.slice(0, 8) }}...{{ node.public_key.slice(-8) }}</small></ion-card-title>
                                </ion-card-header>
                                <ion-card-content>
                                    <div class="card_wrap"> 
                                        <h2>ROLE: {{ node.role || "N/D" }}</h2>
                                        <ion-badge v-if="node.online" color="success">ONLINE</ion-badge>
                                        <ion-badge v-else color="danger">OFFLINE</ion-badge>
                                        <h2>PUBLIC IP: {{ node.pub_ip || "N/D" }}</h2>
                                        <h2>LOCAL IP: {{ node.ip_endpoint || "N/D" }}</h2>
                                        <h2>CORE VERSION: {{ node.core_version || "N/D" }}</h2>
                                        <h2>SEED: {{ node.seed || "N/D" }}</h2>
                                        <ion-label class="ion-text-wrap">
                                            <h2 class="last_block" v-if="node.last_block">LAST BLOCK HASH: {{ arrayBufferToBase64(node.last_block.hash) || "N/D" }}</h2>
                                        </ion-label>
                                        <ion-button class="btn_stats" :router-link="`/nodes/${node.public_key}`" expand="block">Stats</ion-button>
                                    </div>
                                </ion-card-content>
                            </ion-card>
                         </ion-col>
                    </fieldset>
                </ion-row>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonToolbar, IonContent, IonButton, IonGrid, IonCol, IonCard, IonRow, IonLabel, IonToggle, IonSearchbar } from '@ionic/vue';
import axios from 'axios';

export default {

    components: {
        IonPage,
        IonHeader,
        IonSearchbar,
        IonContent,
        IonToolbar,
        IonButton,
        IonGrid,
        IonCol,
        IonCard,
        IonRow,
        IonLabel,
        IonToggle,
    },
    data() {
        return {
            nodes: [],
            pollingHome: null,
            fieldSetNodes: [],
            darkMode: localStorage.getItem('dark-theme') === "true" ? true : false,
            networkMap: [],
            searchNode: ''
        }
    },
    computed: {},
    methods: {
    
        filterByPk(event) {
            const val = event.target.value;
            let filteredNodes = this.nodes
            if (val && val.trim() != '') {
                this.searchNode = val.trim()
                filteredNodes = this.filterSourceNodes(this.nodes)
                // console.log(val, filteredNodes, 'filter test');
            }
            this.fieldSetNodes = this.mapNodes(filteredNodes)
        },
        filterSourceNodes(nodes) {
            return this.searchNode? nodes.filter(node => node.public_key.toLowerCase().indexOf(this.searchNode.toLowerCase()) > -1) : nodes 
        },
        pollDataHome() {
        this.pollingHome = setInterval(() => {
            axios.get(/* process.env.BACKEND_URL + */ 'https://monitor.affidaty.net/api/v1/nodesMonitor/nodes')
            .then(res => {
                this.nodes = res.data;
                this.fieldSetNodes = this.mapNodes(this.filterSourceNodes(this.nodes))
            })
            .catch(err => {
                console.log(err, "Error: Request failed with status code 404");
            })
            }, 2000)
        },
    
        mapNodes(nodes){
            let setNodes = {};
            let othernodes = [];
            nodes.forEach(node => {
                let dictionaryNetworkMap = this.networkMap.find(el => el.hash == node.nw_config.name);
                if (dictionaryNetworkMap) {
                    node.nw_config.name = dictionaryNetworkMap.name +" ("+ node.nw_config.name+")";
                }
                if (node && node.nw_config && node.nw_config.name) {
                    setNodes[node.nw_config.name] = node.nw_config.name;
                } else {
                    othernodes = [...othernodes, node];
                }
            })

            const result = [
                ...Object.keys(setNodes).map(el => {
                    const networkNodes = this.nodes.filter(eli => eli.nw_config.name == el)
                    return {
                        nw_name: el,
                        nodes: networkNodes,
                        online: networkNodes.filter(eli => eli.nw_config.name == el && eli.online).length,
                        count : networkNodes.length
                    }
                }),
                {
                    nw_name: "UNKNOW",
                    nodes: othernodes,
                    online: othernodes.filter(el => el.status).length,
                    count : othernodes.length
                }
            ]
            return result
        },

        arrayBufferToBase64( buffer ) {
            var binary = '';
            var bytes = new Uint8Array( buffer );
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode( bytes[ i ] );
            }
            return window.btoa( binary );
        },

        ionViewWillLeave() {
            clearInterval(this.pollingHome);
            // console.log(this.pollingHome, 'unmounted');
        },

        truncateText: (text = '', maxLen = 20) => (text.length > maxLen) ? `${text.substring(0, maxLen)}…` : text,

        toggleTheme(isDarkMode) {
            document.body.setAttribute('color-theme', (isDarkMode? 'dark': 'light'))
            localStorage.setItem('dark-theme', isDarkMode)
        },
  },
   ionViewWillEnter() {
       axios.get('/networkMap.json')
        .then(res => {
            this.pollDataHome();
            this.toggleTheme(this.darkMode);
            this.networkMap = res.data;
        })
    },
}
</script>

<style scoped>

    fieldset {
        width: 100%;
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 15px;
        margin-bottom: 15px;
        padding-left: 0.75em;
        padding-right: 0.75em;
        border: 4px groove (internal value);
    }

    .card_wrap {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    strong {
        font-size: 20px;
    }

    .header_el {
        display: flex;
        justify-content: center;
        padding: 10px 10px;
    }
    .logo_img {
        height: 60px;
        margin-right: 10px;
    }

    h2 {
        font-size: 15px;
        padding: 10px 0;
    }

    .border_card_online {
        border: 3px solid #30df75;
    }
    .border_card_offline {
        border: 3px solid #fe4961;
    }

    .node_col {
        width: 300px;
        padding: 20px;
        display: inline-block;
    }

    .btn_stats {
        margin-top: auto;
    }

    ion-toggle {
    --background: #000;
    --background-checked: #7a49a5;

    --handle-background: #7a49a5;
    --handle-background-checked: #000;
    }

    @media screen and (max-width: 767px) {

    .logo_img {
        display: none;
    }

    .title > h1 {
        font-size: 15px;
    }

    fieldset {
        width: 100%;
        text-align: center;
        padding: 5px 20px;
        margin: 10px 10px;
    }

    fieldset legend strong {
        font-size: 15px;
        margin-bottom: 10px;
    }

    .card_title {
        font-size: 22px !important;
    }

    .node_col {
        width: 280px;
        padding: 10px;
    }
}

@media screen and (max-width: 1024px) {

    .logo_img {
        display: none;
    }

    .title > h1 {
        font-size: 15px;
    }

    fieldset {
        text-align: center;
        padding: 0px 25px;
        margin: 15px 10px;
    }

    fieldset legend strong {
        font-size: 15px;
        margin-bottom: 15px;
    }

    .card_title {
        font-size: 24px !important;
    }
}

</style>
