import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import VNetworkGraph from "v-network-graph"
import "v-network-graph/lib/style.css"

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
/*
import VueSocketIO from 'vue-socket.io';
import SocketIO from "socket.io-client";
const SocketInstance = SocketIO.connect('http://localhost:4113', {
    query: {
        //token: window.localStorage.getItem('auth')
    }
});*/

//export const SocketInstance = socketio('http://localhost:4113');
/* Theme variables */
import './theme/variables.css';

const app = createApp(App)
  .use(IonicVue)
  .use(VNetworkGraph)
  .use(router)
  /*.use(new VueSocketIO({
    debug: true,
    connection: SocketInstance
  }))*/;

router.isReady().then(() => {
  app.mount('#app');
});