import { createRouter, createWebHistory } from '@ionic/vue-router';
import Nodes from '../pages/Nodes.vue';

const routes = [
  {
    path: '/',
    redirect: '/nodes'
  },
  {
    path: '/nodes',
    component: Nodes
  },
  {
    path: '/nodes/:id',
    component: () => import('../pages/NodeDetails.vue') 
  },
  {
    path: '/map/:network',
    component: () => import('../pages/NodeMap.vue') 
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/nodes'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
